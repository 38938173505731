import React from "react";
import "../Pages.css"; // Create a CSS file for the animations
import gm from "../../../assets/GM_Logo.png";
import { ButtonBase, Typography } from "@mui/material";
import { GameButtonCustom } from "../../MUI_Components/Components";
import { useNavigate } from "react-router-dom";
const GameMasterSplash = () => {
  const navigate = useNavigate();

  return (
    <div className="gameOverScreen">
      <div className="crownImageContainer">
        <img className="crownImage" src={gm} alt="GM Logo" />
      </div>
      <br />
      <Typography
        sx={{
          fontFamily: "inherit",
          paddingLeft: "10px",
          paddingRight: "10px",
          color: "white",
        }}
        variant="h3"
      >
        You are the Game Master
      </Typography>
      <GameButtonCustom
        onClick={() => {
          navigate("/game-master");
        }}
        sx={{ marginTop: "20px" }}
      >
        GM Console
      </GameButtonCustom>
    </div>
  );
};

export default GameMasterSplash;
