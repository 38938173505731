import { Avatar, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import "./CurseLineItem.css";
import Divider from "@mui/material/Divider";
import gemsImage from "../../../assets/curseGem.png";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GameButtonCustom } from "../../MUI_Components/Components";
import coinsImageLowRes from "../../../assets/coinsLowRes.png";
import { motion } from "framer-motion";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ColorButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: "#404040",
  borderRadius: "50px",
  height: "25px",
  fontSize: "15px",
  width: "80px",
  color: "white",
  marginTop: "17.5px",
  "&:hover": {
    backgroundColor: "#404040",
  },
}));

const CurseLineItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [openSelectChallenges, setOpenSelectChallenges] = useState(false);
  const [openSelectPlayer, setOpenSelectPlayer] = useState(false);
  const [errorMessage, setErrorMessage] = useState("err");
  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);
  const [selectedChallenge, setSelectedChallenge] = useState();

  useEffect(() => {
    setSelectedChallenge(Object.values(props?.challenges)[0]?.challengeId);
  }, [props?.challenges]);

  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSelectChallenges = () => {
    setOpenSelectChallenges(false);
  };
  const handleCloseSelectPlayer = () => {
    setErrorMessage("");
    setOpenSelectPlayer(false);
  };

  const handleClickPurchase = (curseId, curseType) => {
    switch (curseId) {
      case 0:
        buyCurse(curseId, curseType);
        break;
      case 1:
        setOpenSelectChallenges(true);
        break;
      default:
        setSelectedPlayer(null);
        setOpenSelectPlayer(true);
        break;
    }
  };

  async function isResAnError(res, curseId) {
    console.log("res", res);
    console.log("hello");
    if (res === "An error occurred.") {
      setAlertMessage("An Error Occured, Please Try Again");
      setAlertSeverity("error");
    } else if (res === "Insufficient coins.") {
      setAlertMessage("Not Enough Coins");
      setAlertSeverity("error");
    } else if (res === "Insufficient gems.") {
      setAlertMessage("Not Enough Gems");
      setAlertSeverity("error");
    } else {
      setAlertMessage("Successfully Purchased");
      setAlertSeverity("success");
    }
    return res;
  }
  async function buyCurse(curseId, type) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    console.log("pack", props);
    var raw = JSON.stringify({
      gameId: props.user.game,
      playerId: props.uid,
      curseId: curseId.toString(),
      pack: props.pack,
    });

    if (curseId === 1) {
      let json = JSON.parse(raw);
      json.data = selectedChallenge;
      raw = JSON.stringify(json);
      console.log(raw);
    }

    if (curseId > 1) {
      let json = JSON.parse(raw);
      json.data = selectedPlayer;
      json.cursedBy = props.user.name;
      raw = JSON.stringify(json);
      console.log(raw);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);

    if (props.curse.type === "curse") {
      props.setLoading("gems");
      await fetch(
        "https://purchaseitemwithgems-nykygdtd7a-uc.a.run.app",
        requestOptions
      )
        .then((response) => {
          props.setLoading(false);
          return response.text();
        })
        .then((result) => isResAnError(result, curseId))
        .catch((error) => {
          console.log("bad res");
          props.setLoading(false);
        });
    } else {
      props.setLoading("coins");

      await fetch(
        "https://purchaseitem-nykygdtd7a-uc.a.run.app",
        requestOptions
      )
        .then((response) => {
          props.setLoading(false);
          return response.text();
        })
        .then((result) => isResAnError(result, curseId))
        .catch((error) => {
          console.log("bad res", error);
          props.setLoading(false);
        });
    }

    setOpen(true);
    setLoading(false);
  }

  function handleBuySingleCard() {
    handleCloseSelectChallenges();
    console.log(selectedChallenge);
    buyCurse(props.curse.curseId, props.curse.type);
  }

  function handleBuyCurse() {
    console.log(selectedPlayer);
    if (selectedPlayer !== null) {
      let player = selectedPlayer;
      handleCloseSelectPlayer();
      console.log(player);
      buyCurse(props.curse.curseId, props.curse.type);
    }
  }

  const handleChange = (event) => {
    setSelectedChallenge(event.target.value);
  };
  const handleChangePlayer = (event) => {
    setSelectedPlayer(event.target.value);
  };

  const imageLoaded = () => {
    setImageLoading(false);
    setTimeout(() => setPulsing(false), 600);
  };

  return (
    <>
      <div className="curseLineItemContainer">
        <Box className="curseItemImageContainer">
          <img
            src={props.curse.image}
            alt="curseImage"
            className="curseItemImage"
          />
        </Box>
        <div className="lineItemText">
          <Typography sx={{ fontSize: "20px" }}>{props.curse.title}</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {props.curse.description}
          </Typography>
        </div>
        <div className="buttonContainer">
          <ColorButton
            loading={loading}
            onClick={() => {
              handleClickPurchase(props.curse.curseId, props.curse.type);
            }}
          >
            {props.curse.type === "power" && (
              <>
                <img
                  src={coinsImageLowRes}
                  alt="coins"
                  className="purchaseButtonImage"
                ></img>
                {props.curse.value}
              </>
            )}
            {props.curse.type === "curse" && (
              <>
                <img
                  src={gemsImage}
                  alt="gems"
                  className="purchaseButtonImage"
                ></img>
                {props.curse.value}
              </>
            )}
          </ColorButton>
        </div>
      </div>
      <Divider
        variant="fullWidth"
        sx={{
          backgroundColor: "white",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        sx={{ marginBottom: "60px" }}
      >
        <Alert
          onClose={props.handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openSelectChallenges} onClose={handleCloseSelectChallenges}>
        <DialogTitle>Reshuffle Single Card</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reshuffle a specific card back into the deck (there is a
            <span className="italics"> small</span> chance you may get the same
            card again)
          </DialogContentText>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={Object.values(props.challenges)[0].challengeId}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            {props.challenges.map((challenge, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "calc(100% - 24px)",
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    setSelectedChallenge(challenge.challengeId); // Update the state on div click
                  }}
                >
                  <FormControlLabel
                    value={challenge.challengeId}
                    control={
                      <Radio
                        checked={selectedChallenge === challenge.challengeId} // Add checked prop
                        sx={{
                          "&.Mui-checked": {
                            color: "purple",
                          },
                        }}
                      />
                    }
                    style={{ marginRight: 0 }}
                  />
                  <Typography
                    sx={{
                      fontWeight:
                        challenge.challengeId === selectedChallenge && "bold",
                    }}
                  >
                    {challenge.title}
                  </Typography>
                </div>
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectChallenges}>Cancel</Button>
          <GameButtonCustom
            variant="contained"
            sx={{ width: "100px" }}
            onClick={() => {
              handleBuySingleCard();
            }}
          >
            Buy
          </GameButtonCustom>
        </DialogActions>
      </Dialog>

      <Dialog open={openSelectPlayer} onClose={handleCloseSelectPlayer}>
        <DialogTitle>{props.curse.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.curse.description}</DialogContentText>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedPlayer} // Controlled by state
            name="radio-buttons-group"
            onChange={handleChangePlayer}
          >
            {props?.members?.length > 0 &&
              props.images &&
              props.members
                .filter((member) => member.username !== props.user.name)
                .map((member, index) => (
                  <div
                    key={index}
                    style={{
                      width: "calc(100% - 24px)",
                      borderRadius: "10px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setSelectedPlayer(member.id); // Update the state on div click
                    }}
                  >
                    <FormControlLabel
                      value={member.id}
                      control={
                        <Radio
                          checked={selectedPlayer === member.id} // Add checked prop
                          sx={{
                            "&.Mui-checked": {
                              color: "purple",
                            },
                          }}
                        />
                      }
                      label=""
                      style={{ marginRight: 0 }}
                    />
                    <Avatar
                      src={props.images[member?.id]}
                      alt={member.username}
                      style={{ marginRight: 8 }}
                    />
                    <Typography
                      sx={{
                        fontWeight: member.id === selectedPlayer && "bold",
                      }}
                    >
                      {member.username}
                    </Typography>
                  </div>
                ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectPlayer}>Cancel</Button>
          <GameButtonCustom
            variant="contained"
            sx={{ width: "100px" }}
            onClick={() => {
              handleBuyCurse();
            }}
          >
            Buy
          </GameButtonCustom>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CurseLineItem;
