import { Alert, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./JoinGame.css";
import { useNavigate } from "react-router-dom";
import {
  cancelGMRequest,
  getDB,
  isGameStarted,
  joinGameAsGM,
} from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { onValue, ref } from "firebase/database";
import gm from "../../assets/GM_Logo.png";
import { GameButton, RoundedCard } from "../MUI_Components/Components";

const JoinGame = (props) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const [user, setUser] = useState();
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    const query = ref(getDB(), "/users/" + currentUser.uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        setUser(data);
      }
    });
  }, [currentUser.uid]);

  function joinGameAsGameMaster() {
    isGameStarted(code.toUpperCase()).then((res) => {
      if (!res) {
        setError("No Game With That Code Exists");
      } else {
        setError("");
        joinGameAsGM(currentUser.uid, user.name, code, user.phone);
        setHasRequested(true);
        props.setGameFromChild(code);
      }
    });
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <RoundedCard sx={{ padding: "30px", marginTop: "50px" }}>
        <div className="gm_logo_container">
          <img src={gm} alt="Game Master Logo" style={{ width: "150px" }} />
        </div>
        <div className="joinContainer">
          {!hasRequested ? (
            <>
              {error && (
                <Alert severity="error" className="errorAlert">
                  {error}
                </Alert>
              )}
              <TextField
                label="Game Code"
                className="joinField"
                variant="outlined"
                onChange={(e) => setCode(e.target.value)}
              />
              <GameButton
                variant="contained"
                className="joinButton"
                onClick={joinGameAsGameMaster}
              >
                Request to be Game Master
              </GameButton>
            </>
          ) : (
            <>
              <Typography variant="h5" className="requestPendingText">
                Your request is pending...
              </Typography>
              <GameButton
                variant="contained"
                className="cancelButton"
                onClick={() => {
                  setHasRequested(false);
                  cancelGMRequest(currentUser.uid, user.name, code);
                }}
              >
                Cancel Request
              </GameButton>
            </>
          )}
          <GameButton
            variant="contained"
            className="backButton"
            onClick={() => navigate("/")}
          >
            Back
          </GameButton>
        </div>
      </RoundedCard>
    </div>
  );
};

export default JoinGame;
