import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import coinsImageLowRes from "../../../../assets/coinsLowRes.png";
import curseGem from "../../../../assets/curseGem.png";
import "../../Leaderboard/Leaderboard.css";
import styled from "@emotion/styled";
import { getOrdinal } from "../../../../utils/utils";

const BaseBoxStyleBackground = styled(Box)(
  ({ width, height, mainColor, secondaryColor }) => ({
    width: width,
    minHeight: height,
    marginBottom: "30px",
    position: "relative",
    zIndex: 0,
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "5px",
    alignItems: "center",
    borderRadius: "15px",
    backgroundColor: "#181716", // Set the main background to solid blue
    "&:before": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      inset: 0,
      padding: "5px",
      borderRadius: "15px",
      background: `linear-gradient(200deg, ${mainColor}, ${secondaryColor})`,
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  })
);

const GMLeaderboardLineItem = ({
  userData,
  gradientId,
  avatarSize,
  iconSize,
  coins,
  mainColor,
  secondaryColor,
  width,
  height,
  rank,
}) => {
  return (
    <BaseBoxStyleBackground
      width={width}
      mainColor={mainColor}
      secondaryColor={secondaryColor}
      height={height}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Avatar
          src={userData.image}
          alt={userData.username}
          sx={{
            width: avatarSize,
            left: 0,
            height: avatarSize,
            marginLeft: "10px",
          }}
        >
          {userData.name?.substring(0, 1)}
        </Avatar>
        <Typography
          variant="h6"
          sx={{
            wordBreak: "break-all",
            marginLeft: "10px",
          }}
        >
          {userData.name}
        </Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img
            src={curseGem}
            alt="coins"
            style={{ width: "20px", height: "20px" }}
          />
          <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
            {userData.gems}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img
            src={coinsImageLowRes}
            alt="coins"
            style={{ width: "20px", height: "20px" }}
          />
          <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
            {userData.coins}
          </Typography>
        </div>
        <>
          <svg width={0} height={0}>
            <linearGradient id="firstPlaceGradient" x1={1} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor="rgba(241,184,74,1)" />
              <stop offset={1} stopColor="rgba(207,113,8,1)" />
            </linearGradient>
          </svg>
          <svg width={0} height={0}>
            <linearGradient
              id="secondPlaceGradient"
              x1={1}
              y1={0}
              x2={1}
              y2={1}
            >
              <stop offset={0} stopColor="#a8a9ad" />
              <stop offset={1} stopColor="#d7d7d8" />
            </linearGradient>
          </svg>
          <svg width={0} height={0}>
            <linearGradient id="thirdPlaceGradient" x1={1} y1={0} x2={1} y2={1}>
              <stop offset={0} stopColor="#CD7F32" />
              <stop offset={1} stopColor="#e6b899" />
            </linearGradient>
          </svg>
          {gradientId ? (
            <EmojiEventsIcon
              className="swaying-icon"
              sx={{
                fill: `url(#${gradientId})`,
                marginRight: "5px",
                fontSize: iconSize,
              }}
            />
          ) : (
            <Typography sx={{ marginRight: "10px", marginLeft: "15px" }}>
              {getOrdinal(rank)}
            </Typography>
          )}
        </>
      </div>
    </BaseBoxStyleBackground>
  );
};

export default GMLeaderboardLineItem;
