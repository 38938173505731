import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import BottomNav from "./BottomNav";
import "./Dashboard.css";
import Challenges from "./DashboardPages/Challenges";
import Store from "./DashboardPages/Store";
import Profile from "./DashboardPages/ProfilePage/Profile";
import { auth, deleteGame, getDB } from "../firebase";
import { ref, onValue, get } from "firebase/database";
import Cursed from "./DashboardPages/Cursed";
import GameMasterSplash from "./DashboardPages/HoldingPages/GameMasterSplash";
import Confetti from "react-confetti";
import {
  DateComparison,
  checkEndTime,
  checkLeaderboardTime,
  convertCurseObjectToArrayWithId,
  convertObjectToArrayWithId,
  isUidApproved,
} from "../utils/utils";
import LeaderboardDisplay from "./DashboardPages/Leaderboard/LeaderboardDisplay";
import UnknownLeaderboard from "./DashboardPages/HoldingPages/UnknownLeaderboard";
import Chat from "./DashboardPages/Chat/Chat";
import { ChatProvider } from "../contexts/ChatContext";
import ChatListener from "./DashboardPages/ChatListener";
import { getAuth, getIdTokenResult, onAuthStateChanged } from "firebase/auth";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [isGameMaster, setIsGameMaster] = useState(null);
  const [role, setRole] = useState();
  const [page, setPage] = useState(0);
  const [user, setUser] = useState([]);
  const [game, setGame] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [gameEndedTime, setGameEndedTime] = useState(null);
  const [leaderboardHideTime, setLeaderboardHideTime] = useState(null);
  const [gameEnded, setGameEnded] = useState(null);
  const [majorCurses, setMajorCurses] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameStatus, setGameStatus] = useState();
  const [selectedPack, setSelectedPack] = useState();
  const [gameOver, setGameOver] = useState();
  const [leaderboardHide, setLeaderboardHide] = useState();
  const [pendingCurses, setPendingCurses] = useState([]);
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          const role = idTokenResult.claims.role;
          console.log("User role:", role);
          setRole(role);
        } else {
          console.error("No authenticated user found");
        }
      } catch (error) {
        console.error("Error fetching user role", error);
      }
    };

    fetchUserRole();
  }, []);
  const ChallengesPage = () => {
    return (
      <div>
        <Challenges
          user={user}
          game={game}
          setShowConfetti={setShowConfetti}
          challenges={challenges}
          completedChallenges={completedChallenges}
          uid={currentUser.uid}
          gameEnded={gameEnded}
          pendingCurses={pendingCurses}
        />
      </div>
    );
  };

  const StorePage = () => {
    return (
      <div>
        <Store
          user={user}
          game={game}
          challenges={challenges}
          completedChallenges={completedChallenges}
          uid={currentUser.uid}
          gameEnded={gameEnded}
          selectedPack={selectedPack}
        />
      </div>
    );
  };

  const LeaderboardPage = () => {
    return (
      <div>
        <LeaderboardDisplay user={user} game={game} />
      </div>
    );
  };

  const ProfilePage = () => {
    return (
      <div>
        <Profile
          user={user}
          game={game}
          setGame={setGame}
          uid={currentUser.uid}
          clearChallenges={clearChallenges}
          gameEnded={gameEnded}
          isGameMaster={isGameMaster}
          gameStatus={gameStatus}
          setGameEnded={setGameEnded}
          role={role}
        />
      </div>
    );
  };

  const CursedPage = () => {
    return (
      <div style={{ height: "100dvh" }}>
        <Cursed
          user={user}
          game={game}
          curses={majorCurses}
          uid={currentUser.uid}
          gameEnded={gameEnded}
        />
      </div>
    );
  };

  const ChatPage = () => {
    return <Chat user={user} game={game} />;
  };

  function RenderPage({ page, isGameMaster, user, majorCurses }) {
    if (
      user.game === "false" ||
      gameStatus === "false" ||
      gameStatus === "ended" ||
      gameStatus === undefined
    ) {
      return <ProfilePage />;
    }
    if (isGameMaster && user.game !== "false") {
      return <GameMasterSplash />;
    }

    if (page === 2 && leaderboardHide === null) {
      return null;
    }
    switch (page) {
      case 0:
        return majorCurses.length === 0 ? <ChallengesPage /> : <CursedPage />;
      case 1:
        return <StorePage />;
      case 2:
        return leaderboardHide === false ? (
          <LeaderboardPage />
        ) : (
          <UnknownLeaderboard />
        );
      case 3:
        return <ChatPage />;
      case 4:
        return <ProfilePage />;
      default:
        return null;
    }
  }

  //Get full user details from user page
  useEffect(() => {
    if (currentUser?.uid?.length === 0) {
      return;
    }
    const query = ref(getDB(), `/users/${currentUser.uid}/`);
    const userRef = onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setUser(data);
      }
    });

    return () => {
      userRef();
    };
  }, [currentUser.uid]);

  //Check if current user is GM
  useEffect(() => {
    if (user.length === 0 || game === null || game === false) {
      return;
    }
    const query = ref(getDB(), "/games/" + user.game + "/gameMasterRequests/");

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        if (isUidApproved(Object.values(data), currentUser.uid)) {
          setIsGameMaster(true);
        } else {
          setIsGameMaster(false);
        }
      } else {
        setIsGameMaster(false);
      }
    });
  }, [game, currentUser.uid, user.game, user.length]);

  useEffect(() => {
    let interval;
    let minsBeforeHideLeaderboard = leaderboardHideTime;
    if (gameStatus === "true" && leaderboardHideTime !== null) {
      interval = setInterval(() => {
        if (checkLeaderboardTime(gameEndedTime, minsBeforeHideLeaderboard)) {
          setLeaderboardHide(true);
        } else {
          setLeaderboardHide(false);
        }
        if (checkEndTime(gameEndedTime)) {
          setGameStatus("ended");
          setGameOver(true);
          clearInterval(interval);
        }
      }, 1000); // Check every second
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [gameStatus, gameEndedTime, leaderboardHideTime]); // Dependencies array

  //Act on game Started field changing
  useEffect(() => {
    if (user.length === 0 || game === null || game === false) {
      return;
    }
    const startedQuery = ref(getDB(), "/games/" + user.game + "/host/started/");
    let startedRef = onValue(startedQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        if (checkEndTime(gameEndedTime)) {
          setGameEnded(true);
          setGameStatus("ended");
          return;
        }
        console.log("tjdsad", data);
        setGameStatus(data);
        if (data === "ended") {
          setGameEnded(true);
        }
      } else {
        deleteGame(currentUser.uid, user.game);
        setGameEnded(null);
      }
    });

    return () => {
      startedRef();
    };
  }, [game, user.game, user.length, currentUser.uid, gameEndedTime]);

  //Get Game and Host details
  useEffect(() => {
    if (user.length === 0) {
      return;
    }

    const gameQuery = ref(getDB(), `/games/${user.game}/`);
    get(gameQuery)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setGame(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const hostQuery = ref(getDB(), "/games/" + user.game + "/host");
    onValue(hostQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data !== null) {
        console.log("EndTimeFromDB", data.endTime);
        setGameEndedTime(data.endTime);
        setLeaderboardHideTime(data.hideLeaderboardMins);
        setSelectedPack(data.pack);
      }
    });
  }, [user, gameOver]);

  //Use Effects to get Current challenges, curses, and completed challenges
  useEffect(() => {
    if (user.length === 0 || user.game === "false") {
      return;
    }
    const coreQuery = "/games/" + user.game + "/players/" + currentUser.uid;
    //Get Challenges of User
    const challengesQuery = ref(getDB(), coreQuery + "/currentChallenges/");
    console.log("challengesQuery", challengesQuery);
    const currentChallengesRef = onValue(challengesQuery, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists() && Object.values(data).length > 0) {
        data = Object.values(convertObjectToArrayWithId(data)).filter(
          (item) => item !== null
        );
        setChallenges(data);
      }
    });

    // Get Completed Challenges of User
    const completedChallengesQuery = ref(
      getDB(),
      coreQuery + "/completedChallenges/"
    );
    const completedChallengesRef = onValue(
      completedChallengesQuery,
      (snapshot) => {
        let data = snapshot.val();
        if (snapshot.exists() && Object.values(data).length > 0) {
          data = Object.values(convertObjectToArrayWithId(data)).filter(
            (item) => item !== null
          );
          console.log("completed", data);
          setCompletedChallenges(data);
        }
      }
    );

    // Get Current Curses Query
    const cursesQuery = ref(
      getDB(),
      "/games/" + user.game + "/players/" + currentUser.uid + "/curses/major/"
    );
    const currentCursesRef = onValue(cursesQuery, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists() && Object.values(data).length > 0) {
        data = Object.values(convertCurseObjectToArrayWithId(data)).filter(
          (item) => item !== null
        );
        setMajorCurses(data);
      } else {
        setMajorCurses([]);
      }
    });

    const pendingCursesQuery = ref(
      getDB(),
      "/games/" +
        user.game +
        "/players/" +
        currentUser.uid +
        "/completedCurses/"
    );
    const pendingCursesRef = onValue(pendingCursesQuery, (snapshot) => {
      let data = snapshot.val();
      console.log("data", data);
      if (snapshot.exists() && Object.values(data).length > 0) {
        data = Object.values(convertCurseObjectToArrayWithId(data)).filter(
          (item) => item !== null
        );
        console.log("data", data);
        setPendingCurses(data);
      } else {
        setPendingCurses([]);
      }
    });

    return () => {
      pendingCursesRef();
      currentChallengesRef();
      completedChallengesRef();
      currentCursesRef();
    };
  }, [currentUser.uid, user.game, user.length]);

  useEffect(() => {
    if (gameEndedTime !== null) {
      DateComparison(gameEndedTime?.toString());
    }
  });

  function clearChallenges() {
    setChallenges([]);
    setCompletedChallenges([]);
  }

  return (
    <>
      <ChatProvider>
        <ChatListener user={user} page={page} />
        <div className="pages">
          {showConfetti && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                left: "0px",
                top: "0px",
                zIndex: "23",
              }}
            >
              <Confetti
                initialVelocityY={10}
                gravity={1}
                style={{
                  opacity: 1,
                  transition: "opacity 0.5s",
                }}
                onAnimationEnd={() => console.log("completed confetti")}
              />
            </div>
          )}
          <RenderPage
            page={page}
            isGameMaster={isGameMaster}
            user={user}
            majorCurses={majorCurses}
          />
        </div>
        {gameStatus === "true" && (
          <div className="pageContainer">
            <BottomNav
              game={game}
              page={page}
              setPage={setPage}
              style={{ zIndex: "25" }}
            />
          </div>
        )}
      </ChatProvider>
    </>
  );
};

export default Dashboard;
