import React, { useEffect, useState } from "react";
import { getDB } from "../../../firebase";
import { onValue, ref } from "firebase/database";
import { convertObjectToArray } from "../../../utils/utils";
import { useAuth } from "../../../contexts/AuthContext";
import { CircularProgress, Typography } from "@mui/material";
import "./Leaderboard.css";
import { TitleBox } from "../../MUI_Components/Components";
import LeaderboardLineItem from "./LeaderboardLineItem";

const LeaderboardDisplay = (props) => {
  const { currentUser } = useAuth();

  const [leaderboard, setLeaderboard] = useState([]);
  const [images, setImages] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.user.game === "false") {
      return;
    }
    const gameQuery = ref(
      getDB(),
      "/games/" + props.user.game + "/leaderboard/"
    );
    onValue(gameQuery, (snapshot) => {
      const data = snapshot.val();
      console.log("leaderboard", data);
      if (snapshot.exists() && data !== null) {
        setLeaderboard(convertObjectToArray(data));
      }
    });
  }, [currentUser.uid, props.user.game]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      gameId: props.user.game,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const fetchUserImages = fetch(
      "https://getplayerimages-nykygdtd7a-uc.a.run.app",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result));

    const fetchUserNames = fetch(
      "https://getplayerusernames-nykygdtd7a-uc.a.run.app",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result));

    Promise.all([fetchUserImages, fetchUserNames])
      .then(([imagesResult, namesResult]) => {
        setImages(imagesResult);

        setNames(namesResult);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.game.players, props.user.game]);

  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     uids: Object.keys(props.game.players),
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const fetchUserImages = fetch(
  //     "https://getuserimages-nykygdtd7a-uc.a.run.app",
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => JSON.parse(result));

  //   const fetchUserNames = fetch(
  //     "https://getusernames-nykygdtd7a-uc.a.run.app",
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => JSON.parse(result));

  //   Promise.all([fetchUserImages, fetchUserNames])
  //     .then(([imagesResult, namesResult]) => {
  //       setImages(imagesResult);
  //       console.log("images", imagesResult);

  //       setNames(namesResult);
  //       console.log("names", namesResult);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }, [props.game.players]);

  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   console.log(user.game)
  //   var raw = JSON.stringify({
  //     gameId: user.game,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   }
  //   console.log(game.players)

  //   const fetchUserNames = fetch(
  //     "https://getplayerusernames-nykygdtd7a-uc.a.run.app",
  //     requestOptions
  //   )
  //     .then((response) => response.text())
  //     .then((result) => JSON.parse(result));

  //   Promise.all([fetchUserNames])
  //     .then(([namesResult]) => {
  //       setNames(namesResult);
  //       console.log("names", namesResult);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TitleBox>
        <Typography variant="h3" className="gradient-text">
          LEADERBOARD
        </Typography>
      </TitleBox>
      {loading && <CircularProgress sx={{ color: "purple" }} />}
      {!loading && leaderboard.length > 0 && (
        <>
          {leaderboard.length > 0 && (
            <LeaderboardLineItem
              userData={{
                image: images[leaderboard[0].name],
                name: names[leaderboard[0].name],
                username: names[leaderboard[0].name]?.username,
                coins: leaderboard[0].coins,
              }}
              gradientId={`firstPlaceGradient`}
              avatarSize={"90px"}
              iconSize={"60px"}
              mainColor={"#f1b84a"}
              secondaryColor={"#cf7108"}
              coins={leaderboard[0].coins}
              width={"95%"}
              height={"80px"}
            />
          )}
          {leaderboard.length > 1 && (
            <LeaderboardLineItem
              userData={{
                image: images[leaderboard[1].name],
                name: names[leaderboard[1].name],
                username: names[leaderboard[1].name]?.username,
                coins: leaderboard[1].coins,
              }}
              gradientId={`secondPlaceGradient`}
              avatarSize={"80px"}
              iconSize={"40px"}
              mainColor={"#E1E1E1"}
              secondaryColor={"#AFAFAF"}
              coins={leaderboard[1].coins}
              width={"90%"}
              height={"70px"}
            />
          )}
          {leaderboard.length > 2 && (
            <LeaderboardLineItem
              userData={{
                image: images[leaderboard[2].name],
                name: names[leaderboard[2].name],
                username: names[leaderboard[2].name]?.username,
                coins: leaderboard[2].coins,
              }}
              gradientId={`thirdPlaceGradient`}
              avatarSize={"80px"}
              iconSize={"40px"}
              mainColor={"#CD7F32"}
              secondaryColor={"#e6b899"}
              coins={leaderboard[2].coins}
              width={"90%"}
              height={"70px"}
            />
          )}
          {leaderboard?.length > 3 &&
            leaderboard.slice(3).map((player, index) => {
              return (
                <LeaderboardLineItem
                  userData={{
                    image: images[player.name],
                    name: names[player.name],
                    username: names[player.name]?.username,
                    coins: player.coins,
                  }}
                  avatarSize={"30px"}
                  iconSize={"40px"}
                  mainColor={"#808080"}
                  secondaryColor={"#808080"}
                  coins={player.coins}
                  width={"90%"}
                  height={"60px"}
                  key={player.name}
                  rank={index + 4}
                />
              );
            })}
        </>
      )}
      {!loading && leaderboard.length === 0 && (
        <>
          <Typography>No players have made the leaderboard yet</Typography>
        </>
      )}
    </div>
  );
};

export default LeaderboardDisplay;
