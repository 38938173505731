import React, { useEffect, useState } from "react";
import GMRequests from "./GMRequests";
import ApprovedGMList from "./ApprovedGMList";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../../firebase";

const GMComponent = ({
  gameStarted,
  user,
  gmRequests,
  setGMRequests,
  gmReqImages,
  setGMReqImages,
}) => {
  return (
    <>
      {/* GM Approved List */}
      <ApprovedGMList
        gmReqImages={gmReqImages}
        gmRequests={gmRequests}
        gameStarted={gameStarted}
      />
      {/* GM Requests List */}
      <GMRequests
        gmRequests={gmRequests}
        gmReqImages={gmReqImages}
        user={user}
      />
    </>
  );
};

export default GMComponent;
