import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { RoundedTextField } from "./MUI_Components/Components";
import { Badge, Button } from "@mui/material";
import ChatTextField from "./DashboardPages/Chat/ChatTextField";
import { useChat } from "../contexts/ChatContext";

const BottomNav = (props) => {
  function changePage(value) {
    props.setPage(value);
  }

  const { hasUnreadMessages } = useChat();

  return (
    <>
      {props.page === 3 && <ChatTextField />}
      <Box sx={{ width: "100%" }}>
        <>
          <BottomNavigation
            showLabels
            value={props.page}
            onChange={(event, newValue) => {
              changePage(newValue);
            }}
            sx={{
              ".Mui-selected svg": { color: "purple" },
              ".Mui-selected": { color: "purple" },
              styleOverrides: { color: "purple" },
            }}
          >
            <BottomNavigationAction
              label="Challenges"
              icon={
                <CheckCircleOutlineIcon
                  sx={{ ".Mui-selected": { color: "purple" } }}
                />
              }
            />
            <BottomNavigationAction
              label="Store"
              icon={<LocalGroceryStoreIcon />}
            />
            <BottomNavigationAction
              label="Leaderboard"
              icon={<LeaderboardIcon />}
            />
            <BottomNavigationAction
              label="Chat"
              icon={
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={!hasUnreadMessages}
                >
                  <ChatBubbleIcon />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Profile"
              icon={<AccountCircleIcon />}
            />
          </BottomNavigation>
        </>
      </Box>
    </>
  );
};

export default BottomNav;
