import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
import gm_logo from "../../assets/GM_Logo.png";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import coins from "../../assets/coins.png";
import gems from "../../assets/curseGem.png";
import "../DashboardPages/Pages.css";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "15px",
  height: "40px",
  fontSize: "20px",
  marginTop: "15px",
}));

const ChallengeCard = (props) => {
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  return (
    <div className="ChallengeCardContainerGM">
      {props.challenge.description.includes(":-:GM") && (
        <img src={gm_logo} alt="GM LOGO" className="gmLogo" />
      )}
      <div className="ChallengeCardGM">
        <Typography
          variant="h6"
          sx={{ marginBottom: "3px", fontWeight: "bold" }}
        >
          {props.challenge.title}
        </Typography>
        <div className="rewardContainer">
          <img src={coins} alt="coinsIcon" style={{ height: "30px" }} />
          <Typography variant="h5">{props.challenge.value}</Typography>
          <img
            src={gems}
            alt="curseGem"
            style={{ height: "30px", marginLeft: "20px" }}
          />
          <Typography variant="h5">{props.challenge.gems}</Typography>
          {props.challenge.description.includes(":-:MP") && (
            <PeopleIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
          {props.challenge.description.includes(":-:SP") && (
            <PersonIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
        </div>
        <Typography variant="subtitle2">
          {props.challenge.description
            .replace(":-:GM", "")
            .replace(":-:MP", "")
            .replace(":-:SP", "")}
        </Typography>
        {props.approved && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ColorButton
              variant="contained"
              color="success"
              fullWidth
              onClick={() => {
                setApproveLoading(true);
                props
                  .approveChallenge(props.challenge.challengeId)
                  ?.then(() => {
                    setApproveLoading(false);
                  });
              }}
              disabled={approveLoading || denyLoading}
            >
              {approveLoading ? <CircularProgress size={24} /> : "Approve"}
            </ColorButton>
            <ColorButton
              variant="contained"
              color="error"
              fullWidth
              onClick={() => {
                setDenyLoading(true);
                props.denyChallenge(props.challenge.challengeId)?.then(() => {
                  setDenyLoading(false);
                });
              }}
              disabled={approveLoading || denyLoading}
            >
              {denyLoading ? <CircularProgress size={24} /> : "Deny"}
            </ColorButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeCard;
