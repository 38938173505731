import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "./Dashboard";
import UpdateProfile from "./SettingsPages/UpdateProfile";
import GameMaster from "./GameMaster";
import PreviousGames from "./SettingsPages/PreviousGames";
import Signup from "./PreSignInPages/Signup";
import SignIn from "./PreSignInPages/Signin";
import ForgotPassword from "./PreSignInPages/ForgotPassword";
import Home from "./PreSignInPages/Home";
import Registered from "./DashboardPages/ProfilePage/Components/Registered";

function Layout() {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          minHeight: "calc(100vh - 56px)",
          backgroundColor: "transparent",
          width: "clamp(200px, 400px, 600px)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Route for game-master without layout */}
          <Route
            path="/game-master"
            element={
              <PrivateRoute>
                <GameMaster />
              </PrivateRoute>
            }
          />
          {/* Routes with layout */}
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/update-profile"
              element={
                <PrivateRoute>
                  <UpdateProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/registered"
              element={
                <PrivateRoute>
                  <Registered />
                </PrivateRoute>
              }
            />
            <Route
              path="/previous-games"
              element={
                <PrivateRoute>
                  <PreviousGames />
                </PrivateRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <SignIn />
                </PublicRoute>
              }
            />
            <Route path="/register" element={<Home />} />
            <Route
              path="/forgot-password"
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            {/* Redirect any other paths */}
            <Route path="*" element={<RedirectToCorrectPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

function RedirectToCorrectPage() {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/" /> : <Navigate to="/login" />;
}

export default App;
