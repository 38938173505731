import React, { useEffect, useState } from "react";
import JoinGame from "./GameMasterPages/JoinGame";
import ControlGame from "./GameMasterPages/ControlGame";
import { onValue, ref } from "firebase/database";
import { getDB } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import "./GameMaster.css";
import Loading from "./DashboardPages/HoldingPages/Loading";

const GameMaster = () => {
  const { currentUser } = useAuth();

  const [gameMasters, setGameMasters] = useState();
  const [game, setGame] = useState();
  const [isGameMaster, setIsGameMaster] = useState(null);
  const [user, setUser] = useState();

  function setGameFromChild(gameCode) {
    setGame(gameCode);
  }

  useEffect(() => {
    const query = ref(getDB(), "/users/" + currentUser.uid);
    console.log("checking", currentUser.uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log("currentUser", data);
      if (snapshot.exists()) {
        setUser(data);
        setGame(data.game);
      }
    });
  }, [currentUser.uid]);

  function isUidApproved(array, uid) {
    const matchingItem = array.find((item) => item.uid === uid);
    return matchingItem && matchingItem.status === "approved";
  }

  useEffect(() => {
    if (game !== null && game !== false && user !== null) {
      console.log("game", game);
      const query = ref(getDB(), "/games/" + game + "/gameMasterRequests/");
      return onValue(query, (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          setGameMasters(data);
          console.log("data", data);
          if (isUidApproved(Object.values(data), currentUser.uid)) {
            setIsGameMaster(true);
          } else {
            setIsGameMaster(false);
          }
        } else {
          setIsGameMaster(false);
        }
      });
    }
  }, [game, currentUser.uid, user]);

  return (
    <>
      <div className="gameMaster">
        {isGameMaster === true && <ControlGame game={game} />}
        {isGameMaster === false && (
          <JoinGame setGameFromChild={setGameFromChild} />
        )}
        {isGameMaster === null && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

export default GameMaster;
