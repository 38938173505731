import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";

export const GameButton = styled(Button)(({ theme }) => ({
  width: "300px",
  marginTop: "20px",
  height: "50px",
  backgroundColor: "#3F2E3E",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "#A78295",
  },
  color: "white",
}));

export const GameButtonCustom = styled(Button)(({ theme }) => ({
  backgroundColor: "#3F2E3E",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "#A78295",
  },
  color: "white",
}));

export const GreenGameButton = styled(Button)(({ theme }) => ({
  width: "300px",
  marginTop: "20px",
  height: "50px",
  borderRadius: "50px",
  color: "success",
}));

export const RedGameButton = styled(Button)(({ theme }) => ({
  width: "300px",
  marginTop: "20px",
  height: "50px",
  borderRadius: "50px",
  color: "error",
}));

export const RedGameLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: "300px",
  marginTop: "20px",
  height: "50px",
  borderRadius: "50px",
  color: "error",
}));

export const RealDivider = styled(Divider)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
}));

export const RoundedTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "30px",
    },
  },
});
export const RoundedTextFieldVisible = styled(TextField)({
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "white", // Set label text color to white
  },
  "& .MuiInputLabel-root": {
    // This targets the label directly
    color: "white", // Set label text color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Always white border
      borderRadius: "20px",
    },
    "&:hover fieldset": {
      borderColor: "white", // Border remains white on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border remains white when focused
    },
  },
  "& .MuiInputBase-input": {
    // This targets the input text
    color: "white", // Set input text color to white
  },
});

export const RoundedCard = styled(Card)({
  paddingBottom: "30px",
  borderRadius: "20px",
  backgroundColor: "white",
});

export const TitleBox = styled(Box)(() => ({
  width: "97%",
  height: "60px",
  border: "3px",
  marginLeft: "0.75%",
  borderColor: "#aba7a6",
  borderStyle: "solid",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  borderRadius: "5px",
  marginBottom: "20px",
  backgroundColor: "#181716",
}));

export const BlueIconButton = styled(IconButton)(({ theme }) => ({
  color: "#147efb",
}));
