import {
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  GameButton,
  GreenGameButton,
  RedGameLoadingButton,
} from "../../../MUI_Components/Components";
import {
  convertObjectToArrayWithId,
  endGame,
  futureTime,
} from "../../../../utils/utils";
import { startGame } from "../../../../firebase";
import GMComponent from "../GMProfile/GMComponent";
import DurationPicker from "./DurationPicker";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../../firebase";

const GameConfiguration = (props) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false); // State to control dialog
  const [packName, setPackName] = useState("original");
  const [hours, setHours] = useState(5);
  const [minutes, setMinutes] = useState(0);
  const [hideMins, setHideMins] = useState(30);
  const [gmRequests, setGMRequests] = useState([]);
  const [gmReqImages, setGMReqImages] = useState([]);

  useEffect(() => {
    console.log("props", props);
  }, [props]);

  function getRequestImages(uids) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uids,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => setGMReqImages(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (props.user.game !== "false") {
      const query = ref(getDB(), "/games/" + props.user.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          if (
            data.gameMasterRequests &&
            Object.keys(data.gameMasterRequests).length > 0
          ) {
            setGMRequests(data.gameMasterRequests);
            getRequestImages(Object.keys(data.gameMasterRequests));
          }
        }
      });
    }
  }, [props.user.game]);

  const handleChange = (event) => {
    setPackName(event.target.value);
  };

  async function handleStartGame() {
    props.setError("");
    try {
      console.log(props.user.game, futureTime(hours, minutes), packName);
      const hasGameMaster =
        convertObjectToArrayWithId(gmRequests)?.filter(
          (request) => request.status === "approved"
        ).length > 0;
      await startGame(
        props.user.game,
        futureTime(hours, minutes),
        packName,
        hideMins,
        hasGameMaster
      );
      props.setGameEnded(null);
    } catch (error) {
      console.log("error", error);
      props.setError("Failed to Start Game");
    }
  }

  const handleEndGame = () => {
    setLoading(true);
    endGame(props.user.game);
  };

  return (
    <>
      {props?.gameStatus === "true" && (
        <CountdownTimer
          endTime={props?.endTime}
          hideLeaderboardMins={props.hideLeaderboardMins}
          sx={{ marginBottom: "30px" }}
        />
      )}
      {props?.user?.game &&
        currentUser?.uid === props?.host &&
        props?.value &&
        props?.endTime && (
          <>
            {props?.gameStatus === "false" && (
              <div style={{ width: "100%" }}>
                <DurationPicker
                  hours={hours}
                  minutes={minutes}
                  setHours={setHours}
                  setMinutes={setMinutes}
                  hideMins={hideMins}
                  setHideMins={setHideMins}
                  packName={packName}
                  packList={props?.packList}
                  handleChange={handleChange}
                />
              </div>
            )}
            <Divider />

            {props?.gameStatus === "false" && (
              <GreenGameButton
                fullWidth
                variant="contained"
                onClick={handleStartGame}
                sx={{ marginTop: "10px" }}
                color="success"
                disabled={props.gameStarted === true}
              >
                Start Game
              </GreenGameButton>
            )}
            {props?.gameStatus === "ended" && (
              <>
                <Typography variant="h4">Game Over!</Typography>
                <GameButton
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    props.handleViewResults();
                  }}
                  sx={{ marginTop: "10px", height: "80px" }}
                >
                  View Results
                </GameButton>
              </>
            )}
            {(props?.gameStatus === "false" ||
              props?.gameStatus === "ended") && (
              <RedGameLoadingButton
                fullWidth
                loading={loading}
                variant="contained"
                onClick={props.handleClickOpen}
                sx={{ marginTop: "30px" }}
                color="error"
              >
                Close Game
              </RedGameLoadingButton>
            )}

            <br />
            {/* GM Components List */}
            <GMComponent
              gameStarted={props.gameStarted}
              user={props.user}
              gmRequests={gmRequests}
              setGMRequests={setGMRequests}
              setGMReqImages={setGMReqImages}
              gmReqImages={gmReqImages}
            />
            {props?.gameStatus === "true" && (
              <>
                <RedGameLoadingButton
                  fullWidth
                  variant="contained"
                  loading={loading}
                  onClick={() => setConfirmOpen(true)} // Open confirmation dialog
                  sx={{ marginTop: "30px" }}
                  color="error"
                >
                  End Game
                </RedGameLoadingButton>
                {/* Confirmation Dialog */}
                <Dialog
                  open={confirmOpen}
                  onClose={() => setConfirmOpen(false)}
                  sx={{
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                  }}
                >
                  <DialogTitle>Confirm End Game</DialogTitle>
                  <DialogContent>
                    Are you sure you want to end the game? This action cannot be
                    undone.
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setConfirmOpen(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setConfirmOpen(false);
                        handleEndGame(); // Call end game function
                      }}
                      color="error"
                      variant="contained"
                    >
                      End Game
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
    </>
  );
};

export default GameConfiguration;
